import { useEffect } from "react";
import styles from "./styles.module.css";
import Asset from "@/app/components/Assets";

export default function AlertModal({
  modal,
  modalType,
  modalData,
  handleModal,
}: {
  modal: boolean;
  modalType: string;
  modalData: any;
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const icon =
    modalType === "success" ? (
      <Asset type={"icon"} id={"checkmark"} color={"#00800080"} size={20} />
    ) : (
      <Asset type={"icon"} id={"close"} color={"#ca000080"} size={20} />
    );

  const closeModal = () => {
    handleModal(null, null);
  };

  // Effect with timeout to close the modal
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleModal(null, null);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [handleModal]);

  return (
    <>
      {modal && (
        <div className={styles.alertModal}>
          <div>
            {icon}
            <span>{modalData}</span>
            <div onClick={closeModal}>
              <Asset
                type={"icon"}
                id={"close"}
                color={"var(--dark)"}
                size={20}
                asAvatar={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
