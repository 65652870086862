import { useState, MouseEvent } from "react";
import { ethers } from "ethers";
import styles from "./styles.module.css";
import { getSigninInfo } from "@/app/utils/auth";

import Loader from "@/app/components/Loader";
import Asset from "@/app/components/Assets";
import { CHAIN_ID } from "@/app/utils/constants";
import { parseErrorMessage } from "@/app/utils/functions";

const SIGNIN_STEPS = [
  {
    id: "connect",
    title: "Connect wallet",
    description: `Connect with your <a href="https://metamask.io/">MetaMask</a> wallet`,
  },
  {
    id: "switch",
    title: "Switch network",
    description: "Switch to the Ethereum Mainnet network in your wallet",
  },
  {
    id: "sign",
    title: "Sign message",
    description:
      "Sign a message in your wallet to verify that you are the owner",
  },
];

function Steps({ step }: { step: number }) {
  const text = `Step ${step + 1} of 3`;
  return (
    <div className={styles.steps}>
      <p>{text}</p>
      <div>
        {Array.from({ length: 3 }).map((_, index) => {
          const stepClassName =
            index < step ? styles.activeStep : styles.inactiveStep;
          return <span key={index} className={stepClassName}></span>;
        })}
      </div>
    </div>
  );
}

function StepDescription({ step }: { step: number }) {
  const description = SIGNIN_STEPS[step].description;

  if (step === 0) {
    return (
      <p>
        Connect your{" "}
        <a href="https://metamask.io/" target="_blank" aria-label="metamask">
          <Asset type={"logo"} id={"metamask"} size={16} /> MetaMask
        </a>{" "}
        wallet or create an account if you don&apos;t have one
      </p>
    );
  }

  return <p>{description}</p>;
}

function ActionBtn({
  label,
  step,
  setStep,
  setError,
  handleModal,
}: {
  label: string;
  step: number;
  setStep: (step: number) => void;
  setError: (error: string | null) => void;
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const value = Number(event.currentTarget.value);

    // Set states
    setLoading(true);
    setError(null);

    try {
      // Step 0: Connect wallet
      if (value === 0) {
        const provider = new ethers.BrowserProvider((window as any).ethereum);
        if (!provider) {
          throw Error("MetaMask wallet not installed");
        }
        const accounts = await provider.send("eth_requestAccounts", []);
        const address = accounts[0] || "";
        const account = ethers.getAddress(address);
        if (!account) {
          throw Error("Failed to connect wallet");
        }

        const network = await provider.getNetwork();
        const chainId = network.chainId;
        if (!chainId) {
          throw Error("Chain not found");
        }

        if (chainId !== BigInt(CHAIN_ID)) {
          setStep(1);
        } else {
          setStep(1);
          // Wait for 1 second so the user can see the network check
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setStep(2);
        }
        setLoading(false);
        return;
      }

      // Step 1: Switch to the correct network (if needed)
      if (value === 1) {
        const { provider, chainId } = await getSigninInfo();

        if (chainId !== BigInt(CHAIN_ID)) {
          // Chain id to hex
          const chainIdHex = `0x${Number(CHAIN_ID).toString(16)}`;
          await provider.send("wallet_switchEthereumChain", [
            {
              chainId: chainIdHex,
            },
          ]);
        }

        // Update the state
        setStep(2);
        setLoading(false);
        return;
      }

      // Step 2: Sign the Siwe message
      if (value === 2) {
        const { provider, address, chainId } = await getSigninInfo();

        // Check if the user is on the correct network
        if (chainId !== BigInt(CHAIN_ID)) {
          throw Error("Please switch to the correct network");
        }

        const account = ethers.getAddress(address);
        const payload = {
          account: account,
          chainId: Number(chainId),
        };
        const loginRes = await fetch("/api/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const { message } = await loginRes.json();
        if (!loginRes.ok || !message)
          throw Error("Failed to fetch message to sign");

        // Request user to sign the Siwe message
        const siweMessage = `0x${Buffer.from(message, "utf8").toString("hex")}`;
        const signature = await provider.send("personal_sign", [
          siweMessage,
          account,
        ]);
        const payload2 = {
          account: account,
          message: message,
          signature: signature,
        };
        const authorizeRes = await fetch("/api/auth/authorize", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload2),
        });
        if (!authorizeRes.ok) {
          throw Error("Failed to authorize");
        }

        // Update the state
        setStep(3);
        setLoading(false);

        // Close the modal
        handleModal(null, null);

        // Refresh the page
        window.location.reload();
      }
    } catch (error: any) {
      const message = parseErrorMessage(error);
      // Reset loading state
      setLoading(false);
      setError(message);
      console.error(error);
    }
  };

  return (
    <button
      className={styles.actionSignInBtn}
      onClick={handleOnClick}
      value={step}
      disabled={loading}
    >
      {loading && <Loader size={16} />}
      {label}
    </button>
  );
}

function CancelBtn({
  handleModal,
  setStep,
  setError,
}: {
  handleModal: (type: string | null, modalData: any) => void;
  setStep: (step: number) => void;
  setError: (error: string | null) => void;
}) {
  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Reset the state
    setStep(0);
    setError(null);

    // Close the modal
    handleModal(null, null);
  };

  return (
    <button className={styles.cancelSignInBtn} onClick={handleOnClick}>
      Cancel
    </button>
  );
}

export default function SignInModal({
  handleModal,
}: {
  handleModal: (type: string | null, modalData: any) => void;
}) {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const title = SIGNIN_STEPS[step]?.title || "";

  if (step >= SIGNIN_STEPS.length) {
    return null;
  }

  return (
    <div className={styles.signInModalCard}>
      <Steps step={step} />
      <div className={styles.signInModalCardBody}>
        <span>Sign-in</span>
        <div className={styles.signInModalCardTitle}>{title}</div>
        <StepDescription step={step} />
        {error && <p className={styles.signInModalError}>{error}</p>}
        <hr />
        <div className={styles.signInModalCardBtns}>
          <ActionBtn
            label={title}
            step={step}
            setStep={setStep}
            handleModal={handleModal}
            setError={setError}
          />
          <CancelBtn
            handleModal={handleModal}
            setStep={setStep}
            setError={setError}
          />
        </div>
      </div>
    </div>
  );
}
