"use client";
import { useRouter } from "next/navigation";
import {
  useState,
  useContext,
  MouseEvent,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { ModalContext } from "@/app/context/modal";
import { DataContext } from "@/app/context/data";
import useWindowSize from "@/app/hooks/useWindowSize";
import styles from "./styles.module.css";
import { isValidETHAddress } from "@/app/utils/functions";
import { getSingleEnsProfileFromEnsIdeas } from "@/app/lib/domains";

import Asset from "@/app/components/Assets";
import Avatar from "@/app/components/Avatar";

type FilteredResult = {
  id: string;
  name: string | null;
  avatar: string | null;
};

export function SearchInput() {
  const router = useRouter();
  const [search, setSearch] = useState<string>("");
  const { daos } = useContext(DataContext);
  const [filteredResults, setFilteredResults] = useState<{
    type: string;
    results: FilteredResult[];
  } | null>(null);

  useEffect(() => {
    // Check clause
    if (!search) return;

    const isValidAddress: boolean = isValidETHAddress(search);
    const isValidENS: boolean =
      search.length > 6 && search.slice(-4).toLowerCase() === ".eth";

    (async () => {
      if (isValidAddress || isValidENS) {
        const profile = await getSingleEnsProfileFromEnsIdeas(search, true);
        const result = {
          id: profile?.address,
          name: profile?.name || profile?.address,
          avatar: profile?.avatar,
        };
        setFilteredResults({
          type: isValidENS ? "domain name" : "address",
          results: result?.id ? [result] : [],
        });
      } else {
        const filteredDaos = daos.filter((dao) => {
          return dao.name.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredResults({
          type: "decentralized autonomous organization",
          results: filteredDaos,
        });
      }
    })();
  }, [search, daos]);

  const handleOnSearch = (
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const resultId = filteredResults?.results[0]?.id;
    const href =
      filteredResults?.type === "decentralized autonomous organization"
        ? `/dao/${resultId}`
        : `/profile/${resultId}`;

    // Check clause
    if (!resultId) return;

    // Reset search
    setSearch("");

    // Redirect to page
    router.push(href);
  };

  const handleOnClick = (
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const resultId = filteredResults?.results[0]?.id;
    const href =
      filteredResults?.type === "decentralized autonomous organization"
        ? `/dao/${resultId}`
        : `/profile/${resultId}`;

    // Check clause
    if (!resultId) return;

    // Reset search
    setSearch("");

    // Redirect to page
    router.push(href);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  const handleOnClear = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setSearch("");
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const resultId = filteredResults?.results[0]?.id;
      const href =
        filteredResults?.type === "decentralized autonomous organization"
          ? `/dao/${resultId}`
          : `/profile/${resultId}`;

      // Check clause
      if (!resultId) return;

      // Reset search
      setSearch("");

      // Redirect to page
      router.push(href);
    }
  };

  return (
    <div className={styles.searchInputContainer}>
      <div className={styles.searchInput}>
        <div onClick={handleOnSearch}>
          <Asset
            type={"icon"}
            id={"search"}
            color={"var(--medium)"}
            size={18}
            asAvatar={true}
          />
        </div>
        <input
          id="search"
          name="search"
          type="text"
          placeholder="Search by dao / ens / address"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={search}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        {search ? (
          <div onClick={handleOnClear}>
            <Asset
              type={"icon"}
              id={"close"}
              color={"var(--medium)"}
              size={18}
              asAvatar={true}
            />
          </div>
        ) : (
          <span></span>
        )}
      </div>
      {search && (
        <div className={styles.searchResultsContainer}>
          <div className={styles.searchResults}>
            {filteredResults && filteredResults?.results?.length > 0 ? (
              <>
                <div className={styles.searchResultsTitle}>
                  {filteredResults?.type}
                </div>
                {filteredResults?.results.map((result: any) => {
                  const { id, name, avatar } = result;
                  return (
                    <button
                      key={id}
                      className={styles.searchResult}
                      onClick={handleOnClick}
                    >
                      {avatar && <Avatar size={24} src={avatar} />}
                      <span>{name}</span>
                    </button>
                  );
                })}
              </>
            ) : (
              <div className={styles.searchResultsMsg}>
                Your search didn&apos;t match any records
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default function SearchBtn() {
  const { width } = useWindowSize();
  const { handleModal } = useContext(ModalContext);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleModal("search", null);
  };

  if (width < 768) {
    return (
      <button
        id="search"
        aria-label="Search"
        className={styles.searchBtn}
        onClick={handleOnClick}
      >
        <Asset
          type={"icon"}
          id={"search"}
          color={"var(--dark-blue)"}
          size={24}
          asAvatar={true}
        />
      </button>
    );
  }

  return <SearchInput />;
}
